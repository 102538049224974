<template>
    <div>
        <h2>Dashboard</h2>
        <p>This page is only visible to users that are currently logged in</p>
    </div>
</template>

<script>
export default {
    name: 'Dashboard',
};
</script>

<style lang="scss" scoped></style>